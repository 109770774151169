import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';

export const MEMBER_POSTS_PAGE_BUNDLE_NAME = 'member-posts-page';

export const MEMBER_POSTS_PAGE_TAB_PUBLICATIONS = 'publications';
export const MEMBER_POSTS_PAGE_TAB_DRAFTS = 'drafts';
export const MEMBER_POSTS_PAGE_TAB_SUBMISSIONS = 'submissions';

export const POST_STATUS_BY_MEMBER_POSTS_PAGE_TAB = {
  [MEMBER_POSTS_PAGE_TAB_PUBLICATIONS]: POST_STATUS.published,
  [MEMBER_POSTS_PAGE_TAB_DRAFTS]: POST_STATUS.unpublished,
  [MEMBER_POSTS_PAGE_TAB_SUBMISSIONS]: POST_STATUS.scheduled,
};

export const MEMBER_POSTS_PAGE_SIZE = 25;
