import { get } from 'lodash';
import {
  IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH,
  IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH,
  IS_MOBILE_DESIGN_SETTINGS_ENABLED_PATH,
  isExperimentEnabled,
  SECTION_POST_LIST,
  getPostFontSizesPath,
  getLayoutName,
  LAYOUT_SLIDER,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_POST_LIST_MOBILE_SETTINGS } from '@wix/communities-blog-experiments';

import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import { getAppSettings, getAppSettingsValue } from './app-settings-base-selectors';
import { getLayoutType } from './layout-selectors';
import { getSection } from './section-selectors';

export const getIsMobileSettingEnabled = (state, key) =>
  getAppSettingsValue({
    state,
    key,
    fallback: false,
  });

export const getIsMobileDisplaySettingsEnabled = (state) =>
  getIsMobileSettingEnabled(state, IS_MOBILE_DISPLAY_SETTINGS_ENABLED_PATH);

export const getIsMobileLayoutSettingsEnabled = (state) =>
  getIsMobileSettingEnabled(state, IS_MOBILE_LAYOUT_SETTINGS_ENABLED_PATH);

export const getIsMobileDesignSettingsEnabled = (state) =>
  getIsMobileSettingEnabled(state, IS_MOBILE_DESIGN_SETTINGS_ENABLED_PATH);

export const getShouldUsePostListMobileTitleFontSize = (state) => {
  const section = getSection(state);
  return (
    isExperimentEnabled(state, EXPERIMENT_POST_LIST_MOBILE_SETTINGS) &&
    section === SECTION_POST_LIST &&
    getIsMobile(state) &&
    get(
      getAppSettings(state),
      getPostFontSizesPath(getLayoutType(state, section, true), section, `titleFontSize`, true),
    )
  );
};
export const getShouldUsePostListMobileDescriptionFontSize = (state) => {
  const section = getSection(state);
  return (
    isExperimentEnabled(state, EXPERIMENT_POST_LIST_MOBILE_SETTINGS) &&
    section === SECTION_POST_LIST &&
    getIsMobile(state) &&
    get(
      getAppSettings(state),
      getPostFontSizesPath(getLayoutType(state, section, true), section, `descriptionFontSize`, true),
    )
  );
};
export const getPostListMobileLayoutNameForTitleFontColor = (state) => {
  const section = getSection(state);
  if (
    !isExperimentEnabled(state, EXPERIMENT_POST_LIST_MOBILE_SETTINGS) ||
    !getIsMobile(state) ||
    section !== SECTION_POST_LIST
  ) {
    return null;
  }
  return getLayoutName(getLayoutType(state, section, true));
};

export const getShouldUsePostListMobileSliderArrowColor = (state) => {
  return (
    isExperimentEnabled(state, EXPERIMENT_POST_LIST_MOBILE_SETTINGS) &&
    getIsMobile(state) &&
    getIsMobileLayoutSettingsEnabled(state) &&
    isMobileSliderLayout(state)
  );
};

export const getShouldUsePostListMobileSliderBorderStyles = (state) =>
  isExperimentEnabled(state, EXPERIMENT_POST_LIST_MOBILE_SETTINGS) &&
  getIsMobile(state) &&
  getIsMobileDesignSettingsEnabled(state) &&
  isMobileSliderLayout(state);

const isMobileSliderLayout = (state) =>
  getLayoutType(state, SECTION_POST_LIST, true) === LAYOUT_SLIDER ||
  (!getLayoutType(state, SECTION_POST_LIST, true) && getLayoutType(state, SECTION_POST_LIST) === LAYOUT_SLIDER);
