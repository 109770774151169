export function initWindowResize(onInit) {
  if (typeof window === 'undefined') {
    return;
  }

  const windowResizeId = 'communities-window-resize-script';
  if (document.getElementById(windowResizeId)) {
    return;
  }

  window.wixTpaWindowResize = {
    elSelector: '#root',
    enableViewportFix: false,
    onInit
  };

  const s = document.createElement('script');
  s.setAttribute(
    'src',
    'https://static.parastorage.com/services/communities-window-resize/1.4.0/communities-window-resize.min.js',
  );
  s.async = false;
  s.id = windowResizeId;
  document.querySelector('body').appendChild(s);
}
